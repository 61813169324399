import * as React from "react";
import Layout from "../../components/layout/Layout.component";
import Header from "../../components/header/Header.component";
import Section from "../../components/layout/section/Section.component";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as style from "./Playground.style.module.scss";

const PlayGround = () => {
  const page = {
    introduction: "PlayGround",
    header: "Time for some fun!",
    description:
      "See my projects that I have created to make your day more pleasant",
  };

  return (
    <Layout>
      <Header page={page} />
      <Section>
        <div className={style.card_list}>
          <Link to="color-palette" className={style.card}>
            <article>
              <StaticImage
                src="./color-palette/color-palette.png"
                alt="alt"
                as="div"
                className={style.article_image}
              />
              <div className={style.overlayer}>
                <h2>Color palette</h2>
              </div>
            </article>
          </Link>
          <Link to="blackboard" className={style.card}>
            <article>
              <StaticImage
                src="./blackboard/blackboard.jpg"
                alt="alt"
                as="div"
                className={style.article_image}
              />
              <div className={style.overlayer}>
                <h2>Blackboard</h2>
              </div>
            </article>
          </Link>
          <Link to="background-generator" className={style.card}>
            <article>
              <StaticImage
                src="./background-generator/background-generator.jpg"
                alt="alt"
                as="div"
                className={style.article_image}
              />
              <div className={style.overlayer}>
                <h2>Background Generator</h2>
              </div>
            </article>
          </Link>
          <Link to="piano" className={style.card}>
            <article>
              <StaticImage
                src="./piano/piano.png"
                alt="alt"
                as="div"
                className={style.article_image}
              />
              <div className={style.overlayer}>
                <h2>Piano</h2>
              </div>
            </article>
          </Link>
        </div>
      </Section>
    </Layout>
  );
};
export default PlayGround;

export function Head() {
  return <title>Bartłomiej Nowak - frontend developer | PlayGround</title>;
}
